import React from "react";
import Axios from "axios";
import * as constants from "../constants";

export function AgentsRequests() {
  
  this.getAgentPublicDetails = async () => {

    return Axios.get(
      constants.BASEURL +
      "Agents/AgentPublicDetails/"
    )
      .then((response) => {
        return response.data;
      })

      .catch(function (error) {
        console.log(error);
        return "error";
      });
  };

  this.getAgentPublicListing = async (searchLikeVal = 0, searchText = "", countryId = 0, pageNumber = 1, pageSize = 50) => {

    return Axios.get(
      constants.BASEURL +
      `Agents/AgentPublicListing?searchLikeVal=${searchLikeVal}&searchText=${searchText}&countryId=${countryId}&pageNumber=${pageNumber}&pageSize=${pageSize}`
    )
      .then((response) => {
        return response.data;
      })

      .catch(function (error) {
        console.log(error);
        return "error";
      });
  };
  
  this.getTilePermission = async () => {

    return Axios.get(
      constants.BASEURL +
      "Agents/AgentTilePermissions/"
    )
      .then((response) => {
        return response.data;
      })

      .catch(function (error) {
        console.log(error);
        return "error";
      });
  };

  this.pendingProposalRequest = async (startDate, endDate) => {
    let agentId = localStorage.getItem("agentId");

    return Axios.get(
      constants.BASEURL +
      "Agents/PendingProposals/" +
      agentId +
      "/" +
      startDate +
      "/" +
      endDate
    )
      .then((response) => {
        return response.data;
      })

      .catch(function (error) {
        console.log(error);
        return "error";
      });
  };

  this.overDueInvoices = async (startDate, endDate) => {
    let agentId = localStorage.getItem("agentId");

    return Axios.get(
      constants.BASEURL +
      "Agents/studentOverdues/" +
      agentId +
      "/" +
      startDate +
      "/" +
      endDate
    )
      .then((response) => {
        return response.data;
      })

      .catch(function (error) {
        console.log(error);
        return "error";
      });
  };

  this.enrolmentCommission = async (startDate, endDate) => {
    let agentId = localStorage.getItem("agentId");

    return Axios.get(
      constants.BASEURL +
      "Agents/EnrolmentsCommission/" +
      agentId +
      "/" +
      startDate +
      "/" +
      endDate
    )
      .then((response) => {
        return response.data;
      })

      .catch(function (error) {
        console.log(error);
        return "error";
      });
  };

  this.allActiveEnrolments = async (startDate, endDate) => {
    let agentId = localStorage.getItem("agentId");

    return Axios.get(
      constants.BASEURL +
      "Agents/AllEnrolments/" +
      agentId +
      "/1/" +
      startDate +
      "/" +
      endDate
    )
      .then((response) => {
        return response.data;
      })

      .catch(function (error) {
        console.log(error);
        return "error";
      });
  };

  this.agentDocuments = async (startDate, endDate) => {
    let agentId = localStorage.getItem("agentId");

    return Axios.get(
      constants.BASEURL +
      "Agents/AgentDocuments/" +
      agentId +
      "/" +
      startDate +
      "/" +
      endDate
    )
      .then((response) => {
        return response.data;
      })

      .catch(function (error) {
        console.log(error);
        return "error";
      });
  };

  this.paymentRecord = async (startDate, endDate) => {
    let agentId = localStorage.getItem("agentId");

    return Axios.get(
      constants.BASEURL +
      "Agents/PaymentRecord/" +
      agentId +
      "/" +
      startDate +
      "/" +
      endDate
    )
      .then((response) => {
        return response.data;
      })

      .catch(function (error) {
        console.log(error);
        return "error";
      });
  };

  this.communcationsDetails = async (startDate, endDate) => {
    let agentId = localStorage.getItem("agentId");

    return Axios.get(
      constants.BASEURL +
      "Agents/CommunicationsDetails/" +
      agentId +
      "/" +
      startDate +
      "/" +
      endDate
    )
      .then((response) => {
        return response.data;
      })

      .catch(function (error) {
        console.log(error);
        return "error";
      });
  };

  this.agentCommissionDue = async (startDate, endDate) => {
    let agentId = localStorage.getItem("agentId");

    return Axios.get(
      constants.BASEURL +
      "Agents/AgentCommissionDue/" +
      agentId +
      "/" +
      startDate +
      "/" +
      endDate
    )
      .then((response) => {
        return response.data;
      })

      .catch(function (error) {
        console.log(error);
        return "error";
      });
  };

  this.feesPaidEnrolments = async (startDate, endDate) => {
    let agentId = localStorage.getItem("agentId");

    return Axios.get(
      constants.BASEURL +
      "Agents/FeesPaidEnrolments/" +
      agentId +
      "/" +
      startDate +
      "/" +
      endDate
    )
      .then((response) => {
        return response.data;
      })

      .catch(function (error) {
        console.log(error);
        return "error";
      });
  };

  this.sendSms = async (number, message) => {
    let agentName = localStorage.getItem("agentName");
    let senderID = agentName.replace(/\W/gi, "").toUpperCase().substring(0, 9);

    return Axios.get(
      constants.BASEURL + "DirectSMS/" + number + "/" + message + "/" + senderID
    )
      .then((response) => {
        return response.data;
      })

      .catch(function (error) {
        console.log(error);
        return "error";
      });
  };

}

export default AgentsRequests;
